<template>
  <div :id="!$route.path.includes('landing') ? 'app' : ''">
    <div v-if="$root.isLoading" class="loading">
      <div class="loading-box">

        <div class="loading-effect" style="top: 50%;left: 50%;">
          <div class="icon-button" :style="{ 'background-image': 'url(' + logo + ')' }"
            style="height: 3rem;width: auto;">
          </div>
        </div>
        <div class="loading-effect">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner">

          </b-spinner>

        </div>
        <div class="loading-effect" style="top: 80%;">
          {{ $t('loading...') }}

        </div>
      </div>
    </div>
    <Header></Header>
    <router-view></router-view>
    <!-- <Footer></Footer> -->
    <ModalError></ModalError>
    <b-modal id="modal-news" hide-footer hide-header centered>
      <div class="checkbox1">
        <b-form-checkbox id="checkbox-1" class="text-14" v-model="checkBoxStatus" name="checkbox-1" :value="true"
          :unchecked-value="false" required>
          {{ $t('dont_show_again_today') }}
        </b-form-checkbox>
      </div>
      <carousel :items="1" v-if="$root.latestNews.length > 0" :nav="false" :autoplay="true" :dots="true"
        :stagePadding="0" :margin="10">

        <div class="mb-4" v-for="item in $root.latestNews" :key="item.id">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="text-20 font-weight-700 text-primary2">{{ item.title }}</div>
            <span class="ml-2 text-20" @click="$bvModal.hide('modal-news')"><i class="fa fa-close"></i></span>

          </div>
          <div class="content">
            <img :src="item.public_path" alt="" width="100%" class="rounded-1">
            <div class="text-left mt-3">
              <div class="text-secondary text-12 mb-2">{{ item.updated_at.split(' ')[0] }}</div>
              <div class="mb-1 text-14 " v-html="item.description.replace(/(\r\n|\r|\n)/g, '<br>')"></div>

            </div>

          </div>

        </div>
      </carousel>


    </b-modal>



  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import Header from './components/header.vue';
import ModalError from './errorMsg.vue'
export default {
  components: {
    Header, ModalError, carousel,
  },
  watch: {
    $route() {
      var title = this.$route.path.split('/');
      this.title = title[title.length - 1];
      this.path = this.title;

    },
    checkBoxStatus(val) {
      var date = new Date();
      if (val) {
        localStorage.setItem('dunShow', date.getDate());
      } else {

        localStorage.removeItem('dunShow');
      }
    }
  },
  name: 'GVV',
  data() {
    return {
      screenWidth: 0,
      title: '',
      path: '',
      username: '',
      address: '',
      checkBoxStatus: false,
      logo: require('./assets/images/logo-text.png')
    }
  },
  methods: {
    copy(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          alert(`${this.$t('copySuccess')}: ` + value);
        })
        .catch((error) => {
          alert(error);
        });
    },
    logout() {
      localStorage.removeItem('currencyToken');
      localStorage.removeItem('info');
      this.$router.push('/web/sessions/login');
    },
  },
  computed: {
    computedWidth: function () {
      return this.screenWidth + 'px';
    }
  },
  created() {
    this.screenWidth = screen.width;

  },
  mounted() {
    this.$root.getGlobalSetting();  
    var title = this.$route.path.split('/');
    this.title = title[title.length - 1];

    this.path = this.title;
    var info = JSON.parse(localStorage.getItem('info'));
    if (info)
      this.$root.username = info.username;

    this.$root.getCountryList();
    this.$root.getCountryDepositList();
    var token = localStorage.getItem('currencyToken');
    if (token) {
      this.$root.getInfo();
      this.$root.getTicket(1);

    }
  }
}
</script>

<style>
#app {
  font-family: 'Poppins' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
</style>
